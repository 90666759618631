/* styles.css */
.responsive-padding {
  min-height: 95vh;
  padding-left: 328px;
  padding-right: 328px;
}
.responsive-padding-logo {
  padding-left: 167px;
}
.content-padding {
  padding-left: 85px;
  padding-right: 85px;
}
.line-break {
  display: none;
}
.responsive-tag {
  margin-left: 0px;
}
@media (max-width: 1540px) {
  .responsive-padding {
    padding-left: 236px;
    padding-right: 236px;
  }
  .content-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 1440px) {
  .responsive-padding {
    padding-left: 236px;
    padding-right: 236px;
  }
  .content-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
  .line-break {
    display: none;
  }
  .responsive-tag {
    margin-left: 0px;
  }
}
@media (max-width: 1280px) {
  .responsive-padding {
    padding-left: 158px;
    padding-right: 158px;
  }
  .content-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
  .responsive-padding-logo {
    padding: 0;
  }
  .line-break {
    display: none;
  }
  .responsive-tag {
    margin-left: 0px;
  }
}

@media (max-width: 1024px) {
  .responsive-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .content-padding {
    padding-left: 16px;
    padding-right: 16px;
  }
  .responsive-padding-logo {
    padding: 0;
  }
  .responsive-tag {
    margin-left: 0px;
  }
}
@media (max-width: 600px) {
  .responsive-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .content-padding {
    padding-left: 16px;
    padding-right: 16px;
  }
  .responsive-padding-logo {
    padding: 0;
  }
  .line-break {
    display: block;
    width: 100%;
    height: 1px;

    margin: 16px 0;
  }
  .responsive-tag {
    margin-left: 80px;
  }
}
