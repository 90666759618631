/* BV card */
@media only screen and (min-width: 900px) {
	.bvCardContainer {
		max-width: 1170px;
	}
}

@media only screen and (min-width: 1600px) {
	.bvCardContainer {
		max-width: 1350px;
	}
}

#nav-profile-container #nav-profile-drop {
	display: none;
}

#nav-profile-container:hover #nav-profile-drop {
	display: block;
}